import "../scss/main.scss";
import 'slick-carousel';

$(function(){

    var adminBar;
    if( $('#wpadminbar') ){ adminBar = $('#wpadminbar').innerHeight() }else{ adminBar = 0; }

    $('main').not('body.home main').css( 'padding-top', $('#masthead').innerHeight() );

    $('#up').on('click', function(){
        $('html, body').animate({scrollTop: 0}, "slow");
        return false;
    });

    $('#down').on('click', function(){
        $('html, body').animate({scrollTop: $('h1').offset().top - $('#masthead').innerHeight()*2}, "slow");
        return false;
    });

    $('#btNav').on('click', function(){
        $(this).toggleClass('open');
        $('.col--navigation-mobile').toggleClass('open');
    });

    $('.bt-search').on('click', function(){
        $('#search').toggleClass('opensearch');
    });

    $('#nav-header .li--contact, .lien--contact').on('click', function(){
        $('#contact--popup').toggleClass('active');
    });

    $('.contact--close').on('click', function(){
        $('#contact--popup').removeClass('active');
    });

    
    $('.lien--commandes').on('click', function(){
        $('.bloc--onglets').slideToggle();
        if ($('.bloc--onglets').is(':visible')){ $('.bloc--onglets').css('display','flex'); }

        /* On suprime la class active de tous les contenus */
        $('.tab-content').removeClass('active');
        $('.tab-content').hide().delay( 200 );

        /* On supprime la classe pour tous les liens */
        $('.tabs li').removeClass('active');
    });

    $('.nav--commandes > a').on('click', function(){
        $('.bloc--onglets').slideDown();
        if ($('.bloc--onglets').is(':visible')){ $('.bloc--onglets').css('display','flex'); }

        /* On suprime la class active de tous les contenus */
        $('.tab-content').removeClass('active');
        $('.tab-content').hide().delay( 200 );

        /* On supprime la classe pour tous les liens */
        $('.tabs li').removeClass('active');
    });

    $('#nav-header li.nav--commandes > ul.sub-menu li').each(function(){
        var dataTitle = $(this).find('a').attr('title');
        $(this).attr('data', '#'+dataTitle);
    });

    actionsScroll();
    $(window).on('scroll', function(){    
        actionsScroll();
    });

    // INIT ALL FUNCTIONS
    imgBack();
    sliders();
    carrousels();
    if( $('.accordeon').length != 0) { accordeon(); }
    if( $('.tabs').length != 0){ onglets(); }
    animationScroll();
    resize();

    $(window).on('resize', function(){
        resize();
    });
});

function actionsScroll(){
    $('.site-search').removeClass('active');

    var scrolled = $(window).scrollTop();

    if(scrolled > 0 ){
        $('body').addClass('scrolling'); 
    } else {
        $('body').removeClass('scrolling');
    }

    $('#contact--popup').removeClass('active');
}

function imgBack(){
    $('.backImg').each(function(){
        var urlImgCurrent = $(this).children('img').attr('src');
        if (urlImgCurrent && urlImgCurrent != undefined && $(this).children('img').length) {
            $(this).attr('style', 'background-image:url('+urlImgCurrent+'); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;');
            $(this).find('img').remove();		
        }	
    });
}

function sliders(){
    $('.bloc--slider').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: false,
        infinite: true,
        centerPadding: '18px',
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 8,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            }
        ]
    });
}

function carrousels(){
    $('.offres--slider').not('.slick-initialized').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: 'slider',
        responsive: true,
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: '18px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });
}

function accordeon(){
    $('.bloc--accordeon .accordeon--content').hide();

    // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
    // et on remplace l'élément span qu'ils contiennent par un lien :
    $(".accordeon .accordeon--title").on('click', function () {
        // Si le sous-menu était déjà ouvert, on le referme :
        if ($(this).next(".accordeon--content:visible").length != 0) {
            $(this).next(".accordeon--content").slideUp("normal");
            $(this).removeClass('open');
        }
        // Si le sous-menu est caché, on ferme les autres et on l'affiche :
        else {
            $(".accordeon .accordeon--content").slideUp("normal");
            $(".accordeon .accordeon--title").removeClass('open');
            $(this).next(".accordeon--content").slideDown("normal");
            $(this).addClass('open');
        }
        // On empêche le navigateur de suivre le lien :
        return false;
    });
}

function animationScroll(){
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause"
    });

    let scrollAnimFade = gsap.utils.toArray('.elem--anim-opacity');
    scrollAnimFade.forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "top 90%",
                end: "top 75%",
                markers: false,
                scrub: true
            },
            opacity: 0,
            duration: 0.5
        });
    });

    if( $('.title--anim').length != 0) {
        let scrollAnimTitle = gsap.utils.toArray('.title--anim');
        scrollAnimTitle.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true
                },
                opacity: 0,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-top').length != 0) {
        let scrollAnimtop = gsap.utils.toArray('.elem--anim-top');
        scrollAnimtop.forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true,
                    toggleClass: "visible"
                },
                opacity: 0,
                y: 90,
                duration: 0.5
            });
        });
    }

    if( $('.elem--anim-enter').length != 0) {
        gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
    }
}

function onglets(){
    var hauteurMax = 0;
    $('.tab-content').each(function(){
        if( $(this).innerHeight() >= hauteurMax ){
            hauteurMax = $(this).innerHeight();
            $('.tab-content').css('min-height', hauteurMax);
        }
    });

    $('.tabs .tab').on('click', function(e){
        e.preventDefault();

        if( !$(this).parent('li').hasClass('active') ){
            var tab = $(this).attr('data');

            /* On suprime la class active de tous les contenus */
            $('.tab-content').removeClass('active');
            $('.tab-content').hide().delay( 200 );

            /* On supprime la classe pour tous les liens */
            $('.tabs li').removeClass('active');

            /* On ajoute la classe active à l'onglet qui doit être visible */
            $(tab).addClass('active');
            $(tab).show();

            /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
            $(this).parent('li').addClass('active');

            return false;
        }
    });

    $('#nav-header li.nav--commandes > ul.sub-menu li').on('click', function(e){

        if( !$(this).parent('li').hasClass('active') ){
            var tab = $(this).attr('data');

            /* On suprime la class active de tous les contenus */
            $('.tab-content').removeClass('active');
            $('.tab-content').hide().delay( 200 );

            /* On supprime la classe pour tous les liens */
            $('.tabs li').removeClass('active');
            $('.tabs li span[data="'+tab+'"]').parent('li').addClass('active');

            /* On ajoute la classe active à l'onglet qui doit être visible */
            $(tab).addClass('active');
            $(tab).show();

            return false;
        }
    });
}

function resize(){
    if( $('.offres--slider').length > 0){
        var widthDots = $('.offres--slider ul.slick-dots li').length;
        $('.offres--slider ul.slick-dots li').css('width', 100 / widthDots+'%');
    }
}